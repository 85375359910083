<template>
  <v-row class="my-4 list align-center">
    <v-col
      cols="12"
      md="6"
      class="d-flex justify-space-between justify-md-start align-center"
    >
      <div class="mx-5">
        <v-img
          class="image-container"
          :src="getBased(trainingPathCourse.course_thumbnail)"
          width="200px"
        />
      </div>
      <h2 class="d-flex flex-column justify-center ma-0">
        {{ trainingPathCourse.course_title }}
      </h2>
    </v-col>
    <v-col cols="12" md="6" class="d-flex justify-center justify-md-end mb-3">
      <div>
        <v-btn
          depressed
          color="teal lighten-3  white--text"
          :href="'#modal-courses-1'"
          uk-toggle
          @click="selectCourseModalMethod(trainingPathCourse.course_id)"
          ><b>{{ $t("coursesList.courseInfo") }}</b>
        </v-btn>
        <!-- <v-icon large color="light-blue lighten-2">{{
          trainingPathCourse.icon
        }}</v-icon> -->
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "TrainingPathItem",

  props: ["trainingPathCourse"],

  methods: {
    ...mapActions("CourseInfoModal", ["selectCourseModal"]),
    selectCourseModalMethod(id) {
      this.selectCourseModal(id);
    }
  }
};
</script>

<style lang="scss" scoped>
.image-container {
  border-radius: 25px;
  .list {
    padding: 0 0 0 20px;
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 25px;
  }
}
.v-btn:hover {
  text-decoration: none !important;
}
</style>
