<!-- Bact-52-->

<template>
  <div class="training-path-list">
    <v-container>
      <!-- thumbnail -->
      <section class="heroSlider-sertion">
        <div class="uk-container">
          <div uk-slider="center: true" dir="ltr">
            <div
              class="uk-position-relative uk-visible-toggle uk-light"
              tabindex="-1"
            >
              <ul class="uk-slider-items uk-child-width-1-1 uk-grid uk-slider">
                <li>
                  <div
                    class="uk-card uk-card-default uk-grid-collapse uk-flex-middle uk-margin"
                    uk-grid
                  >
                    <div class="uk-width-3-3@m uk-border-radius-l">
                      <img
                        :src="
                          getBased(
                            TrainingPathList.totalCoursesCount
                              .training_path_thumbnail
                          )
                        "
                        alt=""
                        uk-cover
                        style="width: 100%"
                      />
                      <!-- <div class=" uk-position-cover"></div> -->
                      <canvas width="600" height="400"></canvas>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <ul class="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>
          </div>
        </div>
      </section>
      <!-- course header -->
      <div class="d-flex justify-space-between align-center mb-6 title" flat>
        <div>
          <h3>
            <b>{{ TrainingPathList.totalCoursesCount.training_path_title }}</b>
          </h3>
        </div>
        <div>
          <h3>
            <b class="main-color"> {{ $t("coursesList.coursesCount") }} :</b>
            {{ TrainingPathList.totalCoursesCount.total_courses }}
          </h3>
        </div>
      </div>
      <!-- courses list -->
      <div
        class="list"
        flat
        tile
        v-for="trainingPathCourse in TrainingPathList.trainingPathCourses"
        :key="trainingPathCourse.id"
      >
        <TrainingPathItem :trainingPathCourse="trainingPathCourse" />
      </div>
      <!-- course modal info -->
      <course-info-modal></course-info-modal>
    </v-container>
  </div>
</template>

<script>
import CourseInfoModal from "../../../../core/CourseInfoModal/CourseInfoModal.vue";
import TrainingPathItem from "./components/TrainingPathItem";
import { mapActions, mapState } from "vuex";

export default {
  name: "TrainginPathList",

  components: { TrainingPathItem, CourseInfoModal },

  created() {
    this.getTotalCoursesCount(this.$route.params.id);
    this.getTrainingPathCourses(this.$route.params.id);
  },

  data: function() {
    return {};
  },

  methods: {
    ...mapActions("CourseArea", [
      "getTotalCoursesCount",
      "getTrainingPathCourses"
    ])
  },

  computed: {
    ...mapState("CourseArea", ["TrainingPathList"])
  }
};
</script>

<style lang="scss" scoped>
.training-path-list {
  background-color: #fff;

  .title {
    padding: 1rem;
  }

  .uk-clip-path {
    clip-path: none !important;
    border-radius: 58px !important;
  }
}
</style>
